import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AudioDictationService } from 'src/app/shared/services/audio-dictation.service';



@Component({
  selector: 'app-transcription-modal',
  templateUrl: './transcription-modal.component.html',
  styleUrls: ['./transcription-modal.component.scss'],
})
export class TranscriptionModalComponent implements OnInit {
  
  @Input() responseData: any;
  @Input() transcriptionData: any;
  parsedData: any;

  @ViewChild('orders') orders: ElementRef;
  @ViewChild('doctorNotes') doctorNotes: ElementRef;
  @ViewChild('recommendations') recommendations: ElementRef;

  constructor(
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private spinnerService: NgxSpinnerService,
    private audioDictationService: AudioDictationService,
    
  ) {}

  ngOnInit(): void {
    try {
      if(!this.responseData){
        this.getDoctorNotes();
      }else{
        this.parsedData = this.responseData;
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
      this.toastr.error('Failed to parse response data');
    }
  }

  getDoctorNotes = (): void => {
    if(this.transcriptionData){
      this.audioDictationService
        .getDoctorNotes(this.transcriptionData)
        .then((res) => {
          if (res.code === 200) {
            this.responseData = JSON.parse(res.message);
            this.parsedData = this.responseData;
          }
        })
        .catch((err) => {
          this.spinnerService.hide();
          console.error(err);
          this.toastr.error(err.message);
        });
    }
  };

  copySection(section: string): void {
    let textToCopy = '';
    switch (section) {
      case 'orders':
        textToCopy = this.orders.nativeElement.innerText;
        break;
      case 'doctorNotes':
        textToCopy = this.doctorNotes.nativeElement.innerText;
        break;
      case 'recommendations':
        textToCopy = this.recommendations.nativeElement.innerText;
        break;
    }
    this.copyTextToClipboard(textToCopy);
  }

  copyAll(): void {
    const ordersText = this.orders.nativeElement.innerText;
    const doctorNotesText = this.doctorNotes.nativeElement.innerText;
    const recommendationsText = this.recommendations.nativeElement.innerText;
    const allText = `${ordersText}\n\n${doctorNotesText}\n\n${recommendationsText}`;
    this.copyTextToClipboard(allText);
  }

  private copyTextToClipboard(text: string): void {
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.opacity = '0';
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.toastr.success('Copied to clipboard');
  }
}
